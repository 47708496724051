import React from 'react'
import ReactDOM from 'react-dom'
import firebase from 'firebase/app';
import { App } from './core'
import * as serviceWorker from './serviceWorker'

// TODO [#20]: Add a service worker to allow this app to run offline.
// See:
// - https://github.com/facebook/create-react-app/blob/master/packages/react-scripts/template-typescript/src/serviceWorker.ts
// - https://facebook.github.io/create-react-app/docs/making-a-progressive-web-app

const firebaseConfig = {
  apiKey: 'AIzaSyCh-KJxTFjPkNWkbLpdjC7AcyzZ5qZRoBE',
  authDomain: 'allthemidi.firebaseapp.com',
  databaseURL: 'https://allthemidi.firebaseio.com',
  projectId: 'allthemidi',
  storageBucket: 'allthemidi.appspot.com',
  messagingSenderId: '85237702544',
  appId: '1:85237702544:web:b945f547b649bdf0ad9d15',
  measurementId: 'G-VVNWZ23H22'
}

firebase.initializeApp(firebaseConfig)

const main = (() => {
  const existing = document.getElementById('main')
  if (existing) return existing
  const div = document.createElement('div')
  div.id = 'main'
  document.body.appendChild(div)
  return div
})()

const featureModules = [
  require('./app-allthemidi'),
  require('./app-manyknobs'),
  require('./app-manytoggles'),
  require('./app-manyfaders'),
  require('./app-manypads'),
  require('./app-manykeys'),
]

const features = featureModules.map(m => m.default)

function render() {
  ReactDOM.render(<App features={features} />, main)
}

declare global {
  interface NodeModule {
    hot: any
  }
}

if (module.hot) {
  module.hot.accept('./core', render)
}


render()
serviceWorker.register()
