import React from 'react'
import styled from 'react-emotion'
import { Button, Tabs, Space, Input, Drawer, Select, Divider } from 'antd'
import ColorPicker from '../utils/ColorPicker'
import InstrumentJson from './InstrumentJson'
import { logEvent } from '../utils/Firebase'
// import ErrorBoundary from '../utils/ErrorBoundary'
import { DotsIcon } from '../layout/LayoutIcons'
class InstrumentSettings extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      hash: window.location.hash,
      instrument: this.props.instrument,
      data: this.props.layout,
    }
    this.getOptionsForComponent = this.getOptionsForComponent.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
  }

  showDrawer = () => {
    this.setState({
      visible: true,
    })
  }

  handleClose = e => {
    this.setState({
      visible: false,
    })
  }

  handleInputChange(e) {
    this.props.handleEditItem(e)
  }


  handlePasteItem(instrument, layout) {
    pasteItemFromClipboard(instrument, layout)
    setTimeout(() => {
      this.props.handleEditItem({
        target: {
          name: 'i',
          value: instrument.i,
        },
      })
    }, 100);
  }

  getOptionsForComponent() {
    const channel_options = []
    for (let i = 1; i <= 16; i++) {
      channel_options.push(i)
    }

    const note_options = []
    for (let i = 1; i <= 127; i++) {
      note_options.push(i)
    }

    const value_options = []
    for (let i = 0; i <= 127; i++) {
      value_options.push(i)
    }

    if (this.props.component === 'Fader') {
      return {
        name: 'Fader',
        options: [
          {
            name: 'channel',
            label: 'MIDI Channel',
            type: 'select',
            defaultValue: this.props.channel || '1',
            options: channel_options,
          },
          {
            name: 'note',
            label: 'MIDI CC',
            type: 'select',
            defaultValue: this.props.note || '1',
            options: note_options,
          },
          // {
          //   name: 'value',
          //   label: 'Initial value',
          //   type: 'select',
          //   defaultValue: this.props.value || '1',
          //   options: note_options,
          // },
          // {
          //   name: 'direction',
          //   label: 'Direction',
          //   type: 'select',
          //   defaultValue: this.props.direction || 'vertical',
          //   options: ['vertical', 'horizontal'],
          // },
          {
            name: 'bg',
            label: 'Track color',
            type: 'color',
            defaultValue: this.props.bg || 'tomato',
          },
          {
            name: 'bgactive',
            label: 'Handle color',
            type: 'color',
            defaultValue: this.props.bgactive || 'tomato',
          },
        ],
        instructions: [
          {
            title: 'Description',
            text:
            'Increment/decrement the value of a given CC message in the specified channel',
          },
          {
            title: 'Property - direction',
            text:
              'Accepts string "vertical" (bottom to top) or "horizontal" (left to right) to change the fader direction accordingly.',
          },
        ],
      }
    }
    else if (this.props.component === 'Knob') {
      return {
        name: 'Knob',
        options: [
          {
            name: 'channel',
            label: 'MIDI Channel',
            type: 'select',
            defaultValue: this.props.channel || '1',
            options: channel_options,
          },
          {
            name: 'note',
            label: 'MIDI CC',
            type: 'select',
            defaultValue: this.props.note || '1',
            options: note_options,
          },
          // {
          //   name: 'value',
          //   label: 'Initial value',
          //   type: 'select',
          //   defaultValue: this.props.value || '1',
          //   options: value_options,
          // },
          // {
          //   name: 'direction',
          //   label: 'Direction',
          //   type: 'select',
          //   defaultValue: this.props.direction || 'vertical',
          //   options: ['vertical', 'horizontal', 'circular']
          // },
          {
            name: 'bg',
            label: 'Cap color',
            type: 'color',
            defaultValue: this.props.bg || 'tomato',
          },
          {
            name: 'bgactive',
            label: 'Ring color',
            type: 'color',
            defaultValue: this.props.bgactive || 'tomato',
          },
        ],
        instructions: [
          {
            title: 'Description',
            text:
              'Increment/decrement the value of a given CC message in the specified channel',
          },
          {
            title: 'Property: initial Value',
            text:
              'If the initial value has to be something different than 0 edit this value. For example, for a "pan" knob set this at 62',
          },
        ],
      }
    }
    else if (this.props.component === 'Pad') {
      return {
        name: 'Pad',
        options: [
          {
            name: 'channel',
            label: 'MIDI Channel',
            type: 'select',
            defaultValue: this.props.channel || '1',
            options: channel_options,
          },
          {
            name: 'note',
            label: 'Note',
            type: 'select',
            defaultValue: this.props.note || '1',
            options: note_options,
          },
          // {
          //   name: 'velocity',
          //   label: 'Velocity',
          //   type: 'select',
          //   defaultValue: this.props.velocity || 'fixed',
          //   options: ['fixed', 'radius'],
          // },
          {
            name: 'value',
            label: 'Velocity',
            type: 'select',
            defaultValue: this.props.value || '127',
            options: value_options,
          },
          {
            name: 'text',
            label: 'Label',
            type: 'string',
            defaultValue: this.props.text || '',
          },
          {
            name: 'bg',
            label: 'Background color',
            type: 'color',
            defaultValue: this.props.bg || 'tomato',
          },
          {
            name: 'bgactive',
            label: 'Pressed color',
            type: 'color',
            defaultValue: this.props.bgactive || 'tomato',
          },
        ],
        instructions: [
          {
            title: 'Description',
            text:
            'Send a NoteOn message followed by a NoteOff message of a given note in the specified channel.',
          },
          {
            title: 'Property - velocity',
            text:
              'Accepts string "fixed" or "radius". With a velocity:fixed the pad will always trigger the note with the velocity specified as "value". With velocity:radius tapping closer to the center will sound louder.',
          },
        ],
      }
    }
    else if (this.props.component === 'Keys') {
      return {
        name: 'Keys',
        options: [
          
        ],
        instructions: [
          {
            title: 'Description',
            text:
            'Send a NoteOn message followed by a NoteOff message of a given note in the specified channel.',
          },
          {
            title: 'Property - velocity',
            text:
              'Accepts string "fixed" or "radius". With a velocity:fixed the pad will always trigger the note with the velocity specified as "value". With velocity:radius tapping closer to the center will sound louder.',
          },
        ],
      }
    }
    else if (this.props.component === 'Toggle') {
      return {
        name: 'Toggle',
        options: [
          {
            name: 'channel',
            label: 'MIDI Channel',
            type: 'select',
            defaultValue: this.props.channel || '1',
            options: channel_options,
          },
          {
            name: 'note',
            label: 'MIDI CC',
            type: 'select',
            defaultValue: this.props.note || '1',
            options: note_options,
          },
          // {
          //   name: 'value',
          //   label: 'Value (ON)',
          //   type: 'select',
          //   defaultValue: this.props.value || '1',
          //   options: value_options,
          // },
          {
            name: 'text',
            label: 'Text',
            type: 'textarea',
            defaultValue: this.props.text || '',
          },
          {
            name: 'bg',
            label: 'Background color',
            type: 'color',
            defaultValue: this.props.bg || 'tomato',
          },
          {
            name: 'bgactive',
            label: 'Active color',
            type: 'color',
            defaultValue: this.props.bgactive || 'tomato',
          },
        ],
        instructions: [
          {
            title: 'Description',
            text:
            'Toggle between sending CC messages in the specified channel. The first',
          }
        ],
      }
    }
    else if (this.props.component === 'Text') {
      return {
        name: 'Text',
        options: [
          {
            name: 'text',
            label: 'Text content',
            type: 'textarea',
            defaultValue: this.props.text || '',
          },
          {
            name: 'bg',
            label: 'Background color',
            type: 'color',
            defaultValue: this.props.bg || 'tomato',
          },
          {
            name: 'bgactive',
            label: 'Text color',
            type: 'color',
            defaultValue: this.props.bgactive || 'tomato',
          },
        ],
        instructions: [
          {
            title: 'Description',
            text:
              'Lorem ipsum dolor sit amet morbi dictum sem facilisi condimentum nascetur cras bibendum, porta congue lobortis libero himenaeos pharetra, ipsum taciti quam dui convallis arcu. Lorem ultrices dapibus eros tempor non senectus libero maecenas vivamus, sapien venenatis taciti molestie in class massa.',
          },
        ],
      }
    }
    else {
      return {
        name: this.props.component,
        options: [],
        instructions: [],
      }
    }
  }

  render() {
    const { TabPane } = Tabs

    const hash = window.location.hash
    const appName =  hash.replace(/^.{2}/g, '')
    

    return (
      <div>
        <CornerIconWrapper onClick={this.showDrawer} onTouchStart={this.showDrawer}>
          <CornerIcon
            type="primary"
            visible={this.state.visible.toString()}
            shape="circle"
            size="medium"
            icon={<DotsIcon style={{fontSize: '0.8em'}} />}
          />
        </CornerIconWrapper>
        <StyledDrawer
          placement="right"
          closable={true}
          maskClosable={true}
          onClose={this.handleClose}
          destroyOnClose={true}
          title={null}
          visible={this.state.visible}
          maskStyle={{ background: 'rgba(0,0,0,0.1)' }}
          bodyStyle={{ paddingTop: '0' }}
          headerStyle={{ display: 'none' }}
          width={Math.min(350, document.documentElement.clientWidth * 0.9)}
        >
          <Space direction="vertical" style={{ width: '100%' }}>
            <Tabs defaultActiveKey="1">
              <TabPane tab="Item" key="1">
                  <Select
                    showArrow
                    style={{
                      width: '100%',
                      background: 'var(--c-btn-bg)',
                      color: 'var(--c-btn)',
                    }}
                    defaultValue={this.props.component}
                    placeholder={"Item type"}
                    onChange={value =>
                      this.handleInputChange({
                        target: {
                          name: 'component',
                          value: value,
                        },
                      })
                    }
                  >
                    {((appName === 'allthemidi') || (appName === 'manyfaders')) ? (
                      <Select.Option value="Fader">Fader</Select.Option>
                    ) : null}
                    {((appName === 'allthemidi') || (appName === 'manytoggles')) ? (
                      <Select.Option value="Toggle">Toggle</Select.Option>
                    ) : null}
                    {((appName === 'allthemidi') || (appName === 'manyknobs')) ? (
                      <Select.Option value="Knob">Knob</Select.Option>
                    ) : null}
                    {((appName === 'allthemidi') || (appName === 'manypads')) ? (
                      <Select.Option value="Pad">Pad</Select.Option>
                    ) : null}
                    {((appName === 'allthemidi') || (appName === 'manykeys')) ? (
                      <Select.Option value="Keys">Keys</Select.Option>
                    ) : null}
                    <Select.Option value="Text">Text</Select.Option>
                  </Select>
                

                {this.getOptionsForComponent().options.map((option, i) => {
                  return (
                    <div key={i}>
                      <Divider orientation="left" plain>
                        {option.label}
                      </Divider>

                      {option.type === 'color' ? (
                        <ColorPicker
                          data={this.props.data}
                          name={option.name}
                          colors={this.props.data.colors}
                          currentColor={option.defaultValue}
                          onColorChoose={e => this.handleInputChange(e)}
                        />
                      ) : option.type === 'select' ? (
                        <Select
                          showArrow
                          style={{ width: '100%' }}
                          defaultValue={'' + option.defaultValue + ''}
                          onChange={value =>
                            this.handleInputChange({
                              target: {
                                name: option.name,
                                value: value,
                              },
                            })
                          }
                        >
                          {option.options.map(option => (
                            <Select.Option value={option} key={option}>
                              {option}
                            </Select.Option>
                          ))}
                        </Select>
                      ) : option.type === 'textarea' ? (
                        <Input.TextArea
                          autoSize={{ minRows: 1, maxRows: 6 }}
                          type={option.type}
                          name={option.name}
                          defaultValue={option.defaultValue}
                          onChange={e =>
                            this.handleInputChange({
                              target: {
                                name: e.target.name,
                                value: e.target.value,
                              },
                            })
                          }
                        />
                      ) : option.type === 'number' || 'string' ? (
                        <Input
                          min={option.min}
                          max={option.max}
                          addonBefore={null}
                          type={option.type}
                          name={option.name}
                          defaultValue={option.defaultValue}
                          onChange={e =>
                            this.handleInputChange({
                              target: {
                                name: e.target.name,
                                value: e.target.value,
                              },
                            })
                          }
                        />
                      ) : null}
                    </div>
                  )
                })}

                <Divider orientation="left" plain>
                  {'Actions'}
                </Divider>

                {this.props.children /* TODO: check children in Layout.js */}
              </TabPane>

              <TabPane tab="[ ··· ]" key="2">
                <Space
                  direction="vertical"
                  style={{ width: '100%', marginBottom: '2em' }}
                >
                  <Space
                    direction="vertical"
                    style={{ width: '100%', marginBottom: '2em' }}
                  >
                    <Button
                      onClick={() => copyToClipboard(this.state.instrument)}
                      block
                    >
                      Copy item to clipboard
                    </Button>
                    <Button onClick={() => this.handlePasteItem(this.state.instrument, this.state.layout)} block>
                      Paste item from clipboard
                    </Button>
                    <Divider orientation="left" plain>
                      {this.props.instrument.component + ' JSON'}
                    </Divider>
                    <InstrumentJson 
                      data={this.props.instrument}
                      onEdit={edit =>
                            this.handleInputChange({
                              target: {
                                name: edit.name,
                                value: edit.new_value,
                              },
                            })
                          }
                      />
                  </Space>
                </Space>
              </TabPane>

              {/* <TabPane tab="?" key="3">
                <Space
                  direction="vertical"
                  style={{ width: '100%', marginBottom: '2em' }}
                >
                  {this.getOptionsForComponent().instructions.map(
                    (instruction, i) => {
                      return (
                        <div key={'instructions' + i}>
                          <Divider orientation="left" plain>
                            {instruction.title}
                          </Divider>
                          <p style={{ fontSize: '13px' }}>{instruction.text}</p>
                        </div>
                      )
                    }
                  )}
                </Space>
              </TabPane> */}
            </Tabs>
          </Space>
        </StyledDrawer>
      </div>
    )
  }
}

export default InstrumentSettings


const CornerIconWrapper = styled('div')`
  width: 50px;
  height: 50px;
  transform: translate(-40%, -40%);
  display: flex;
  align-items: center;
  justify-content: center;
`

const CornerIcon = styled(Button)`
  pointer-events: auto !important;
  color: var(--c-btn);
  border: none;
  background: #000;
  ${'' /* box-shadow: 0px 0px 16px rgba(255,255,255,0.3); */}
  ${'' /* transform: ${props => props.visible ? 'rotate(45deg)' : "rotate(-90deg)"};
  opacity: ${props => props.visible ? '0.5' : '1'};
  transition: all 0.2s ease; */}
`

function copyToClipboard(layout) {
  var aux = document.createElement('input')
  aux.setAttribute('value', JSON.stringify(layout))
  document.body.appendChild(aux)
  aux.select()
  document.execCommand('copy')
  document.body.removeChild(aux)

  // Log Firebase event
  logEvent('item-copy')
}

async function pasteItemFromClipboard(instrument, layout) {
  // TODO: Improve pasteItemFromClipboard. On iOS it shows a tooltip saying 'Paste' so it's a two-step action right now :/
  const copy = await navigator.clipboard.readText()

  if (window) {
    const hash = window.location.hash
    var appName = hash.replace(/^.{2}/g, '')

    var updatedLayout = layout
    var foundIndex = updatedLayout.layout.findIndex(
      item => item.i === instrument.i
    )
    updatedLayout.layout[foundIndex] = JSON.parse(copy)
    updatedLayout.layout[foundIndex].i = instrument.i
    updatedLayout.layout[foundIndex].w = instrument.w
    updatedLayout.layout[foundIndex].h = instrument.h
    updatedLayout.layout[foundIndex].x = instrument.x
    updatedLayout.layout[foundIndex].y = instrument.y

    console.log(updatedLayout)

    if (global.localStorage) {
      global.localStorage.setItem(appName + '-currentLayout', updatedLayout)
    }
    // Log Firebase event
    logEvent('item-paste')

    // setTimeout(() => {
    //   window.location.reload()
    // }, 200)
  }
}

const StyledDrawer = styled(Drawer)`
    /* iPhone X and Xs Max */
  @media only screen 
      and (device-width: 375px) 
      and (device-height: 812px) 
      and (-webkit-device-pixel-ratio: 3)
      and (orientation : portrait) { 
        .ant-drawer-body {
          padding-top: 40px !important;
        }
      }
  }
  /* iPhone XR */
  @media only screen 
      and (device-width: 414px) 
      and (device-height: 896px) 
      and (-webkit-device-pixel-ratio: 2) 
      and (orientation : portrait) { 
        .ant-drawer-body {
          padding-top: 40px !important;
        }
      }
  }
  /* iPhone 11 – 1792x828px at 326ppi */
  @media only screen 
    and (device-width: 414px) 
    and (device-height: 896px) 
    and (-webkit-device-pixel-ratio: 2) 
    and (orientation : portrait) { 
        .ant-drawer-body {
          padding-top: 40px !important;
        }
      }
  /* iPhone 11 Pro – 2436x1125px at 458ppi */
  @media only screen 
      and (device-width: 375px) 
      and (device-height: 812px) 
      and (-webkit-device-pixel-ratio: 3)
      and (orientation : portrait) { 
        .ant-drawer-body {
          padding-top: 40px !important;
        }
      }
  /* iPhone 11 Pro Max – 2688x1242px at 458ppi */
  @media only screen 
      and (device-width: 414px) 
      and (device-height: 896px) 
      and (-webkit-device-pixel-ratio: 3) 
      and (orientation : portrait) { 
        .ant-drawer-body {
          padding-top: 40px !important;
        }
      }
    }
  }
`