import React from 'react'
import ReactResizeDetector from 'react-resize-detector'
import Layout from '../layout/Layout'
import ErrorBoundary from '../utils/ErrorBoundary'
import {getFromLS, saveToLS} from '../utils/LocalStorage'
import empty from './templates/empty'
import {demo} from './templates/demo'
import {manyknobs} from './templates/manyknobs'
import {manypads} from './templates/manypads'
import {manyfaders} from './templates/manyfaders'
import {manytoggles} from './templates/manytoggles'
import {manykeys} from './templates/manykeys' 

const hash = window.location.hash
const appName =  hash.replace(/^.{2}/g, '')
const layout = getFromLS(appName + '-currentLayout') || demo()
const saved = getFromLS(appName + '-saved') || []
const templates = () => [empty, demo(), manyknobs(), manypads(), manyfaders(), manytoggles(), manykeys()]

export class AllTheMidi extends React.PureComponent {

  handleResize(width) {
    saveToLS(appName + '-templates', templates())
  }

  render() {
    return (
      <ErrorBoundary>
        <ReactResizeDetector
            handleWidth
            handleHeight
            onResize={width => this.handleResize(width)}
            render={({ width, height }) => (
              <Layout
                appName={appName}
                data={layout}
                store={this.props.store}
                templates={templates()}
                saved={saved}
              />
            )}
            />
      </ErrorBoundary>
    )
  }
}
