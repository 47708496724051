export const manykeys = () => {

  const width = document.documentElement.clientWidth
  const height = document.documentElement.clientHeight
  var itemW = width / 8
  var itemH = height / 8 / 3


  let layout = [{"i":"n-11","w":itemW,"h":itemH,"x":0,"y":itemH,"minW":1,"minH":1,"moved":false,"static":false,"component":"Keys","content":{"text":36,"channel":1,"note":36,"value":127,"min":1,"max":127,"bg":"#000000","bgactive":"#999999","direction":"vertical","velocity":"fixed"}}]

  let data = {
    name: 'Manykeys',
    author: '',
    bg: '#333333',
    layout: layout,
  }
  return data
}


export default manykeys

